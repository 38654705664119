import { IIconComponentProps } from '../../types/icons';
import { twMerge } from 'tailwind-merge';

export default function NauPlainIcon(props: IIconComponentProps) {
  return (
    <svg className={twMerge(props.className)} height='50.385' viewBox='0 0 192.504 50.385' width='192.504'>
      <g data-name='Group 1437' id='Group_1437' transform='translate(-118.903 -98.243)'>
        <g data-name='Group 1429' id='Group_1429' transform='translate(12.593 -310.757)'>
          <path
            d='M17.38.385A19.861,19.861,0,0,1,9.79-.99,12.372,12.372,0,0,1,4.428-5.06,11.119,11.119,0,0,1,2.31-11.55H12.32a4.857,4.857,0,0,0,1.485,3.272,4.774,4.774,0,0,0,3.3,1.128,5.161,5.161,0,0,0,3.3-.962,3.219,3.219,0,0,0,1.21-2.668,3.157,3.157,0,0,0-.962-2.365,7.765,7.765,0,0,0-2.365-1.54A38.043,38.043,0,0,0,14.3-16.06,43.417,43.417,0,0,1,8.2-18.37a11.28,11.28,0,0,1-4.07-3.41,9.5,9.5,0,0,1-1.7-5.885,10.12,10.12,0,0,1,3.905-8.443Q10.23-39.16,16.5-39.16q6.38,0,10.285,3.052a10.927,10.927,0,0,1,4.18,8.5H20.79a3.98,3.98,0,0,0-1.375-2.942,4.844,4.844,0,0,0-3.245-1.073,4.045,4.045,0,0,0-2.75.907,3.273,3.273,0,0,0-1.045,2.613,3.238,3.238,0,0,0,1.76,2.915,28.851,28.851,0,0,0,5.5,2.255,50.865,50.865,0,0,1,6.077,2.42,11.57,11.57,0,0,1,4.042,3.355,8.981,8.981,0,0,1,1.7,5.665A11.116,11.116,0,0,1,29.783-5.5a11.749,11.749,0,0,1-4.868,4.29A16.663,16.663,0,0,1,17.38.385Zm29.26-39V0h-9.4V-38.61Zm50.545,0V0h-9.4V-23.155L79.145,0h-7.59l-8.69-23.21V0H53.46V-38.61H64.57L75.405-11.88,86.13-38.61Z'
            data-name='Path 4813'
            fill={'currentColor'}
            id='Path_4813'
            transform='translate(104 459)'
          />
          <path
            d='M86.655,44.589,75.289,66.306,67.6,80.99a.96.96,0,0,0,.85,1.407H78.477a.948.948,0,0,0,.841-.51l3.174-6.054,4.473-8.55a.577.577,0,0,1,1.023,0l4.484,8.6L95.1,80.921l.5.965a.95.95,0,0,0,.843.512H106.5a.961.961,0,0,0,.851-1.407l-1.164-2.224-.01-.01L99.765,66.447,88.358,44.591a.961.961,0,0,0-1.7,0m-28.376-.545c-.342,0-.687.013-1.034.036-8.981.576-15.817,8.353-15.817,17.354v20a.961.961,0,0,0,.961.961h9.538a.96.96,0,0,0,.96-.961V60.964a5.468,5.468,0,0,1,6-5.435,5.616,5.616,0,0,1,4.935,5.666V81.386a.172.172,0,0,0,.324.08l3.236-6.179,5.968-11.378,1.536-2.944.385-.737A16.93,16.93,0,0,0,58.422,44.044Zm64.77,0a.961.961,0,0,0-.92.961V65.466a5.467,5.467,0,0,1-6.008,5.445,5.637,5.637,0,0,1-4.925-5.687V45a.962.962,0,0,0-.92-.961h-9.62a.961.961,0,0,0-.919.961V60.732l.455.86s-.01,0,0,.01l1.285,2.468.727,1.4,6.655,12.754,1.559,2.984A16.937,16.937,0,0,0,133.6,65.466V45a.961.961,0,0,0-.92-.961Z'
            data-name='Path 4767'
            fill={'currentColor'}
            id='Path_4767'
            transform='translate(165.216 376.279)'
          />
          <path
            d='M4.5,0A4.5,4.5,0,1,1,0,4.5,4.5,4.5,0,0,1,4.5,0Z'
            data-name='Path 4768'
            fill={'currentColor'}
            id='Path_4768'
            transform='translate(141 409)'
          />
        </g>
      </g>
    </svg>
  );
}
