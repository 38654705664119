import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import MyCheckbox from '../FormControls/MyCheckbox/MyCheckbox';
import { toast } from 'react-toastify';
import { LeadCampaignType } from '../../types/leads';
import agent from '../../api/agent';
import { PathRoute } from '../../constants/pathRoute/Route';
import { Link } from 'react-router-dom';
import RegexHelpers from '../../helpers/RegexHelpers';
import { useStore } from '../../store/store';
import { UserActivityCodeE } from '../../enums/UserActivityCode.enums';
import ButtonOpenLinkNewTab from '../Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';
import MyButton from '../Buttons/MyButton/MyButton';

const validationSchemas = {
  car: Yup.object().shape({
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    contactApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
  }),
  property: Yup.object().shape({
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    contactApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
  }),

  trip: Yup.object().shape({
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    contactApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
  }),

  simNau: Yup.object().shape({
    phone: Yup.string()
      .matches(RegexHelpers.phoneRegExp, 'Nieprawidłowy numer telefonu')
      .required('Pole jest wymagane'),
    firstName: Yup.string().required('Pole jest wymagane'),
    lastName: Yup.string().required('Pole jest wymagane'),
    contactApproval: Yup.bool().oneOf([true], 'Pole jest wymagane'),
  }),
};

interface ICallBookingProps {
  leadCampaignType: LeadCampaignType;
}

const CallBooking = ({ leadCampaignType }: ICallBookingProps) => {
  const { userStore } = useStore();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const getLeadCampaignHumanName = (leadCampaignType: LeadCampaignType): string => {
    switch (leadCampaignType) {
      case 'car':
        return 'OC_AC Samochodu';
      case 'property':
        return 'Ubezpieczenie majątkowe';
      case 'trip':
        return 'Ubezpieczenie turystyczne';
      case 'simNau':
        return 'Sim Nau';
      default:
        return '';
    }
  };

  return (
    <div>
      {formSubmitted ? (
        <div>
          <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Dziękujemy za wypełnienie formularza.
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            Skontaktujemy się w dzień roboczy między 8.00 a 18.00. W weekendy{' '}
            <span className={'tw-whitespace-nowrap'}>i święta</span> nie pracujemy.
          </div>

          {leadCampaignType === 'car' && (
            <div onClick={() => userStore.registerUserActivity(UserActivityCodeE.CompareCarInsurancePrice)}>
              <ButtonOpenLinkNewTab buttonText={'Porównaj cenę OC/AC'} link={PathRoute.CAR_INSURANCE_EXTERNAL_LINK} />
            </div>
          )}
          <div className={'tw-flex '}>
            <Link className={'tw-w-full'} to={PathRoute.PUSTY}>
              <Button className={'tw-w-full'} variant='primary'>
                Wróć do strony głównej
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{
            campaignType: leadCampaignType,
            campaignTypeHumanName: getLeadCampaignHumanName(leadCampaignType),
            phone: '',
            firstName: '',
            lastName: '',
            contactApproval: false,
            propertyTypeHouse: false,
            propertyTypeFlat: false,
            propertyTypeHolidayHouse: false,
            propertyTypeHouseInProgress: false,
            propertyTypeWalls: false,
            propertyTypeOthers: false,
          }}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              if (leadCampaignType === 'property') {
                if (
                  !values.propertyTypeHouse &&
                  !values.propertyTypeFlat &&
                  !values.propertyTypeHolidayHouse &&
                  !values.propertyTypeHouseInProgress &&
                  !values.propertyTypeWalls &&
                  !values.propertyTypeOthers
                ) {
                  toast.info('Proszę wybrać typ ubezpieczenia');
                  return;
                }
              }
              await agent.Lead.sendCallBookLead(values);
              setFormSubmitted(true);
            } catch (error) {
              console.error('Error sending request', error);
            } finally {
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchemas[leadCampaignType]}>
          {({ isSubmitting, errors }) => (
            <Form className='tw-w-full '>
              <div className={'tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'}>
                {leadCampaignType === 'property' ? 'Interesuje mnie ubezpieczenie majątku' : 'Wyślij swoje dane'}
              </div>
              <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-6'>
                <MyTextInput name='firstName' placeholder='Imię' />
                <MyTextInput name='lastName' placeholder='Nazwisko' />
                <MyTextInput name='phone' placeholder='Nr telefonu' />

                {leadCampaignType === 'property' && (
                  <div>
                    <div className={'tw-mb-6 tw-mt-2 tw-text-center tw-text-lg tw-text-nau-gray'}>
                      Proszę zaznaczyć przynajmniej jedną opcję
                    </div>
                    <div className={'tw-mx-auto tw-grid tw-max-w-sm tw-grid-cols-2 tw-gap-4'}>
                      <MyCheckbox label='domu' name={`propertyTypeHouse`} type='checkbox' variant={'big'} />
                      <MyCheckbox label='mieszkania' name={`propertyTypeFlat`} type='checkbox' variant={'big'} />
                      <MyCheckbox
                        label='domu letniskowego'
                        name={`propertyTypeHolidayHouse`}
                        type='checkbox'
                        variant={'big'}
                      />
                      <MyCheckbox
                        label='domu w budowie'
                        name={`propertyTypeHouseInProgress`}
                        type='checkbox'
                        variant={'big'}
                      />
                      <MyCheckbox label='tylko murów' name={`propertyTypeWalls`} type='checkbox' variant={'big'} />
                      <MyCheckbox label='inne' name={`propertyTypeOthers`} type='checkbox' variant={'big'} />
                    </div>
                  </div>
                )}

                <MyCheckbox
                  className={'tw-text-nau-green-dark'}
                  label='Przesłanie wypełnionego formularza oznacza zgodę na jednorazowy kontakt telefoniczny w celu przedstawienia oferty ubezpieczenia.'
                  name={`contactApproval`}
                  type='checkbox'
                  variant={'big'}
                />
              </div>

              <div className='tw-mb-4'>
                <MyButton className={'tw-mt-4 tw-w-full'} isLoading={isSubmitting} type='submit' variant={'greenDark'}>
                  Zamów rozmowę
                </MyButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default CallBooking;
