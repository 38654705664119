import { Spinner } from 'react-bootstrap';
import React from 'react';
import { twMerge } from '../../../index';

const variantStyles = {
  primary:
    'tw-bg-nau-green-light after:tw-bg-nau-green-light tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-white tw-shadow-md tw-transition-all tw-duration-500 after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-z-[-1] after:tw-h-full after:tw-w-full after:tw-transition-all after:tw-duration-500 after:tw-content-[""] hover:tw-bg-nau-green-dark hover:after:tw-left-[-101%]',
  greenDark:
    'tw-bg-nau-sea-green after:tw-bg-nau-sea-green tw-relative tw-z-10 tw-overflow-hidden tw-rounded-full tw-px-4 tw-py-2 tw-text-base tw-font-normal tw-text-white tw-shadow-md tw-transition-all tw-duration-500 after:tw-absolute after:tw-left-0 after:tw-top-0 after:tw-z-[-1] after:tw-h-full after:tw-w-full after:tw-transition-all after:tw-duration-500 after:tw-content-[""] hover:tw-bg-nau-green-dark hover:after:tw-left-[-101%]',
};

interface IButtonProps {
  variant?: keyof typeof variantStyles;
  className?: string;
  children?: React.ReactNode;
  href?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export default function MyButton({ variant, className, children, ...props }: IButtonProps) {
  className = twMerge(
    'tw-inline-flex tw-gap-0.5 tw-justify-center tw-overflow-hidden tw-text-sm tw-font-medium tw-transition tw-items-center',
    variant && variantStyles[variant],
    className,
  );

  return (
    <button
      className={className}
      {...props}
      disabled={props.isLoading ? props.isLoading : props.disabled}
      onClick={props.onClick}>
      {props.isLoading ? (
        <div className={'tw-flex tw-items-center'}>
          <span className='tw-mr-4'>
            <Spinner animation='grow' aria-hidden='true' as='span' role='status' size='sm' variant='light' />
          </span>
          <div>Ładowanie</div>
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  );
}
