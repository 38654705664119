import React, { useState } from 'react';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import SubpageHeader from '../../components/SubpageHeader/SubpageHeader';
import PlaneIcon from '../../components/Icons/PlaneIcon';
import CallBooking from '../../components/CallBooking/CallBooking';
import MyButton from '../../components/Buttons/MyButton/MyButton';

const TripInsurancePage = () => {
  const [isCallBookView, setIsCallBookView] = useState<boolean>(false);

  return (
    <CustomerDashboard>
      <div className='tw-mx-auto md:tw-w-1/2'>
        <SubpageHeader
          headerClassName={'tw-text-3xl'}
          iconComponent={<PlaneIcon className={'sm:tw-mr-4'} />}
          iconHeight={35}
          title={'Ubezpieczenie turystyczne'}
        />
        <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6 tw-text-nau-green-dark tw-shadow-md'>
          {isCallBookView ? (
            <CallBooking leadCampaignType={'trip'} />
          ) : (
            <>
              <h2 className='tw-text-xl tw-font-semibold '>Ubezpieczenie turystyczne - ochrona w czasie podróży</h2>
              <p className='tw-mt-8  tw-text-base'>
                Zadbaj o swoje bezpieczeństwo w trakcie każdego wyjazdu za granicę.
              </p>
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-base tw-font-normal'>
                <div>● wybór najkorzystniejszej oferty spośród dostępnych na rynku,</div>
                <div>● współpracujemy z 20 Towarzystwami Ubezpieczeniowymi</div>
                <div>● 100% online</div>
              </div>

              <MyButton
                className={'tw-mt-8 tw-w-full'}
                onClick={() => setIsCallBookView(true)}
                type='button'
                variant={'greenDark'}>
                Zamów rozmowę
              </MyButton>
            </>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
};

export default TripInsurancePage;
