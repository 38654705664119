import { createContext, useContext } from 'react';
import UserStore from './userStore/userStore';
import CircleKStore from './circleKStore/circleKStore';
import SideBarStore from './menuStore/menuStore';
import ModalStore from './modalStore/modalStore';
import CommonStore from './commonStore/commonStore';
import UserProfileStore from './userStore/userProfileStore';
import FileStore from './fileStore/fileStore';
import LoansStore from './loanStore/loansStore';
import DiscountCodeStore from './discountCodeStore/discountCodeStore';
import NotificationStore from './notificationStore/notificationStore';
import ArticlesStore from './articlesStore/articlesStore';
import WalletNauStore from './walletNauStore/walletNauStore';
import PaymentsStore from './paymentsStore/paymentsStore';
import CashbackStore from './cashbackStore/cashbackStore';
import CinemaVoucherStore from './cinemaVoucherStore/cinemaVoucherStore';
import KlStore from './klStore/klStore';

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  userProfileStore: UserProfileStore;
  modalStore: ModalStore;
  menuStore: SideBarStore;
  circleKStore: CircleKStore;
  fileStore: FileStore;
  loansStore: LoansStore;
  discountCodeStore: DiscountCodeStore;
  cinemaVoucherStore: CinemaVoucherStore;
  notificationStore: NotificationStore;
  ArticlesStore: ArticlesStore;
  walletNauStore: WalletNauStore;
  paymentsStore: PaymentsStore;
  cashbackStore: CashbackStore;
  klStore: KlStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  userProfileStore: new UserProfileStore(),
  modalStore: new ModalStore(),
  menuStore: new SideBarStore(),
  circleKStore: new CircleKStore(),
  fileStore: new FileStore(),
  loansStore: new LoansStore(),
  discountCodeStore: new DiscountCodeStore(),
  notificationStore: new NotificationStore(),
  ArticlesStore: new ArticlesStore(),
  walletNauStore: new WalletNauStore(),
  paymentsStore: new PaymentsStore(),
  cashbackStore: new CashbackStore(),
  cinemaVoucherStore: new CinemaVoucherStore(),
  klStore: new KlStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
