import React, { useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { PathRoute } from '../../../constants/pathRoute/Route';
import ButtonOpenLinkNewTab from '../../../components/Buttons/ButtonOpenLinkNewTab/ButtonOpenLinkNewTab';
import { useStore } from '../../../store/store';
import { UserActivityCodeE } from '../../../enums/UserActivityCode.enums';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';
import CallBooking from '../../../components/CallBooking/CallBooking';
import CarIcon from '../../../components/Icons/CarIcon';
import MyButton from '../../../components/Buttons/MyButton/MyButton';

const StartCarInsurance = () => {
  const { userStore } = useStore();

  const [isCallBookView, setIsCallBookView] = useState<boolean>(false);

  return (
    <CustomerDashboard>
      <div className='tw-mx-auto md:tw-w-1/2'>
        <SubpageHeader
          headerClassName={'tw-text-3xl'}
          iconComponent={<CarIcon className={'sm:tw-mr-4'} />}
          title={'Ubezpieczenie komunikacyjne'}
        />
        <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6 tw-text-nau-green-dark tw-shadow-md'>
          {isCallBookView ? (
            <CallBooking leadCampaignType={'car'} />
          ) : (
            <>
              <h2 className='tw-text-xl tw-font-semibold '>
                Ubezpieczenie komunikacyjne – ochrona finansowa pojazdu mechanicznego.
              </h2>
              <div className='tw-mt-8 tw-flex tw-flex-col tw-gap-1 tw-text-base tw-font-normal'>
                <div>● wybór najkorzystniejszej oferty spośród dostępnych na rynku</div>
                <div>● współpracujemy z 20 Towarzystwami Ubezpieczeniowymi</div>
                <div>● rabat -5% na OC lub pakiet OC/AC</div>
                <div>● 100% online</div>
              </div>

              <div className={'tw-mt-8 tw-w-full'}>
                <div onClick={() => userStore.registerUserActivity(UserActivityCodeE.CompareCarInsurancePrice)}>
                  <ButtonOpenLinkNewTab
                    buttonText={'Porównaj cenę OC/AC'}
                    link={PathRoute.CAR_INSURANCE_EXTERNAL_LINK}
                  />
                </div>
                <MyButton
                  className={'tw-mt-2 tw-w-full'}
                  onClick={() => setIsCallBookView(true)}
                  type='button'
                  variant={'greenDark'}>
                  Zamów rozmowę
                </MyButton>
              </div>
            </>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
};

export default StartCarInsurance;
