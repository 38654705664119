export default class DateHelpers {
  static formatDate = (date: Date) => {
    if (date == null) {
      return 'Brak daty';
    }

    const dt = new Date(date);
    let month = (dt.getMonth() + 1).toString();
    let day = dt.getDate().toString();
    const year = dt.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  };
}
