import { useField } from 'formik';
import clsx from 'clsx';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import InputMask from 'react-input-mask';

import { MyTextInputProps } from './types';
import { twMerge } from '../../../index';

export default function MyTextInput({
  name,
  label,
  type = 'text',
  mask,
  hidden,
  className,
  labelClassName,
  wrapperClassName,
  onBlur,
  onChange,
  ...restProps
}: MyTextInputProps) {
  const [field, meta] = useField(name);
  const error = meta.touched && meta.error;

  const isTextClass = labelClassName && labelClassName.includes('tw-text-');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={twMerge(hidden && 'tw-hidden', wrapperClassName)}>
      {label && (
        <label
          className={clsx(
            'tw-mb-1 tw-block tw-text-left tw-text-sm tw-font-medium tw-leading-6',
            isTextClass ? labelClassName : 'tw-text-gray-900',
          )}
          htmlFor={name}>
          {label}
        </label>
      )}
      <div className='tw-relative  tw-rounded-md tw-shadow-sm'>
        {mask ? (
          <InputMask
            mask={mask}
            {...field}
            {...restProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6 ',
              error && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              restProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              className,
            )}
            onBlur={onBlur || field.onBlur}
            onChange={handleChange}
          />
        ) : (
          <input
            {...field}
            {...restProps}
            className={twMerge(
              'tw-block tw-w-full tw-rounded-xl tw-border-0 tw-py-1.5 tw-pl-3 tw-pr-3 tw-ring-1 tw-ring-inset tw-ring-gray-300 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-gray-400 sm:tw-text-sm sm:tw-leading-6 ',
              error && 'tw-placeholder:text-red-300 tw-pr-10 tw-text-red-900 tw-ring-red-300 focus:tw-ring-red-500',
              restProps.disabled && 'tw-bg-gray-100 tw-opacity-80',
              className,
            )}
            onBlur={onBlur || field.onBlur}
            onChange={handleChange}
            type={type}
          />
        )}
        {error && (
          <div className='tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3'>
            <ExclamationCircleIcon aria-hidden='true' className='tw-h-5 tw-w-5 tw-text-red-500' />
          </div>
        )}
      </div>
      <div
        className={`tw-transition-grid-rows tw-grid tw-overflow-hidden tw-duration-200  ${
          error ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]'
        }`}>
        <p className='tw-min-h-0 tw-text-left tw-text-sm tw-text-red-600' id='email-error'>
          {error} &nbsp; {/*&nbsp; is necessary for animation */}
        </p>
      </div>
    </div>
  );
}
