export const groupContainer = {
  display: 'flex',
};

export const controlCheckBoxError = {
  backgroundColor: 'red',
};

export const errorLabel = {
  backgroundColor: '#EF5350',
  padding: '5px',
  borderRadius: '10px',
  color: 'white',
  fontWeight: 'bold',
};
