import { useField } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import * as styles from '../styles';
import { MyRadioButtonProps } from './types';

export default function MyRadioButton(props: MyRadioButtonProps) {
  const [field, { error, touched }] = useField(props.name);
  const [value, setValue] = useState<any>(props.value);

  const selectHandler = (selectedValue: any): void => {
    setValue(selectedValue);
    props.onChange && props.onChange(selectedValue);
  };
  return (
    <Form.Group style={styles.groupContainer as React.CSSProperties} className={props.className}>
      {props.options.map((option, index) => (
        <Form.Check
          isInvalid={touched && !!error}
          key={index}
          {...field}
          {...option}
          checked={option.value == value}
          id={props.id}
          name={props.name}
          className='my-radio-button'
          onChange={(e) => selectHandler(e.target.value)}
          disabled={props.disabled}
          type='radio'
        />
      ))}
      <Form.Control.Feedback style={styles.controlCheckBoxError as React.CSSProperties} type='invalid'>
        {touched && error ? <label style={styles.errorLabel as React.CSSProperties}>{error}</label> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
